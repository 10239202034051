<template>
  <div class="download">
    <div class="item">
      <a href="https://www.uptickproject.com/downloadapp.html" target="blank"
        >iOS Testflight \ Android Google Play</a
      >
      <div class="describe">
        {{ $t('download_desc1') }}
      </div>
    </div>
    <div class="item">
      <a
        href="https://file.uptick.network/app/uptickIrisnet.apk"
        target="blank"
      >
        {{ $t('download_desc2') }}</a
      >

      <div class="describe mb-15">
        {{ $t('download_android') }}
      </div>
    </div>
  </div>
</template>

<script lang="js">

export default {
  name: 'Download',
  props: {},
  data: function() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.download {
  .item {
    margin-top: 44px;
  }
  a {
    font-family: Helvetica;
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #8d52ff;
  }
  .describe {
    margin-top: 23px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
  }
}
</style>
